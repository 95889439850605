<template>
  <div class="shipment-details max-width">
    <div v-if="!editShipment">
      <div class="d-flex justify-end">
        <v-btn
        v-if="isMobile"
        class="c-btn mb-3 mx-2 "
        color="primary"
        height="30"
        @click="editShipment = true"
        ><span class="white--text font-100 font-12 d-flex align-center">
          {{ $t("edit") }}</span
        ></v-btn
      >
      </div>


      <v-row>
        <v-col cols="12" md="5" style="flex-direction: column">
          <div class="radius-15 px-4 py-2 h-100 text-center">
            <slick-carousel
              class="circle-dots h-100 mb-2"
              :class="item.images.length > 1 ? 'pb-7' : ''"
              v-bind="settings"
              v-if="item.images && item.images.length > 0"
            >
              <div
                class="w-100 d-flex align-center"
                v-for="(item, i) in item.images"
                :key="i"
              >
                <img
                  class="object-cover mx-0 w-100 max-300"
                  :class="item.images && item.images.length > 1 ? 'pb-7' : ''"
                  width="100%"
                  height="100%"
                  :src="item.image"
                />
              </div>
            </slick-carousel>

            <img
              v-else-if="$route.name == 'myShipment'"
              class="object-cover mx-auto radius-10 max-300"
              height="100%"
              width="100%"
              :src="
                !item.image1 || item.image1 == '#' ? $defaultImage : item.image1
              "
            />
            <img
              v-else-if="$route.name == 'myProduct'"
              class="object-cover mx-auto radius-10 max-300"
              height="100%"
              width="100%"
              :src="
                !item.image || item.image == '#' ? $defaultImage : item.image
              "
            />
          </div>
        </v-col>
        <!-- details -->
        <v-col cols="12" md="5">
          <!-- category -->
          <div class="mb-7">
            <span class="d-block font-30 font-500 primary--text uppercase"
              >{{ item.title.slice(0, 35) }}
            </span>
          </div>

          <!-- category -->
          <v-row align="center">
            <v-col cols="6" md="5" class="py-0 mt-1 mb-2">
              <div class="d-flex align-center">
                <img
                  width="23"
                  height="20"
                  :src="require('@/assets/images/icons/weight.png')"
                  alt=""
                />
                <span class="font-20 d-block black--text mx-2 font-500"
                  >{{ item.weight }} {{ item.unit || $t("kg") }}
                </span>
              </div>
            </v-col>
            <v-col cols="6" md="5" class="py-0 mt-1 mb-2">
              <div class="d-flex align-center" v-if="item.category">
                <img
                  width="20"
                  height="20"
                  :src="require('@/assets/images/icons/category.png')"
                  alt=""
                />
                <span class="font-20 mx-2 d-block black--text font-500"
                  >{{ item.category.name }}
                </span>
              </div>
            </v-col>
          </v-row>

          <!-- date -->
          <v-row align="center">
            <v-col cols="4" md="5" class="py-0 mt-1 mb-2">
              <div class="d-flex align-center">
                <img
                  width="20"
                  height="20"
                  :src="require('@/assets/images/icons/date.png')"
                  alt=""
                />
                <span class="font-20 mx-2 d-block black--text font-500"
                  >{{ item.date }}
                </span>
              </div>
            </v-col>

            <v-col cols="9" md="5" class="py-0 mt-1 mb-2">
              <div class="d-flex align-center">
                <img
                  width="20"
                  height="20"
                  :src="require('@/assets/images/icons/prise.png')"
                  alt=""
                />
                <span class="font-20 mx-2 d-block black--text font-500"
                  >{{ item.budget }}$
                </span>
              </div>
            </v-col>
          </v-row>

          <v-row align="center" class="py-0 my-3">
            <v-col cols="12" class="py-0 mt-1">
              <div class="d-flex align-center">
                <img
                  width="19"
                  height="17"
                  :src="require('@/assets/images/icons/notes.png')"
                  alt=""
                />
                <span class="font-20 d-block mx-2 black--text font-500">
                  {{ $t("description") }}</span
                >
              </div>
            </v-col>
            <v-col cols="12" class="py-0 mb-2" v-if="item.note">
              <div class="py-1 c-btn">
                <span class="font-18 lightGreyText--text">{{
                  readMore ? item.note : item.note.slice(0, 200)
                }}</span
                ><span
                  v-if="!readMore && item.note.length > 200"
                  @click="readMore = true"
                  style="color: #89bbcb; font-size: 12px; cursor: pointer"
                >
                  read more
                </span>
              </div>
            </v-col>
            <v-col cols="12" class="py-0 mb-2" v-if="item.description">
              <div class="py-1 c-btn">
                <span class="font-18 lightGreyText--text">{{
                  readMore ? item.description : item.description.slice(0, 200)
                }}</span
                ><span
                  v-if="!readMore && item.description.length > 200"
                  @click="readMore = true"
                  style="color: #89bbcb; font-size: 12px; cursor: pointer"
                >
                  read more
                </span>
              </div>
            </v-col>
            
          </v-row>
        </v-col>
        <v-col cols="12" md="1" v-if="!isMobile">
          <div class="d-flex align-center mt-4">
            <v-btn
              class="c-btn mb-10 mx-2"
              color="secondary"
              height="40"
              @click="deleteDialog = true"
              v-if="item.status == 'pending'"
            >
              <v-icon color="white">mdi-delete</v-icon>
            </v-btn>
            <v-btn
             
              class="c-btn mb-10 mx-2"
              color="primary"
              height="40"
              @click="editShipment = true"
              ><span class="white--text font-300 font-22 d-flex align-center">
                {{ $t("edit") }}</span
              ></v-btn
            >
          </div>
        </v-col>
      </v-row>

      <!-- origin address -->

      <!-- Distination Address -->
      <v-row
        justify="center"
        class="pt-2"
        v-if="item.country_destination"
      >
        <v-col
          cols="12"
          md="5"
          align="space-between"
          class="pt-2"
          v-if="item.country_origin"
        >
          <v-col class="text-start" v-if="item.country_origin">
            <h2 class="font-22 font-500 d-block blue--text">
              <img
                width="15"
                height="15"
                :src="require('@/assets/images/icons/address.png')"
                alt=""
              />
              {{ $t("orginAddress") }}
            </h2>
            <ul class="mx-6">
              <li>
                <span class="font-18 font-500 gray--text">{{
                  item.country_origin
                }}</span>
              </li>
              <li>
                <span class="font-18 font-500 gray--text">{{
                  item.city_origin
                }}</span>
              </li>
              <li>
                <span class="font-18 font-500 gray--text">{{
                  item.address_origin
                }}</span>
              </li>
            </ul>
          </v-col>

          <v-col class="text-start">
            <h2 class="font-22 font-500 d-block blue--text">
              <img
                width="15"
                height="15"
                :src="require('@/assets/images/icons/address.png')"
                alt=""
              />
              {{$t("distinationAddress")}}
            </h2>
            <ul v-if="item.country_destination" class="mx-6">
              <li>
                <span class="font-18 font-500 gray--text">{{
                  item.country_destination
                }}</span>
              </li>
              <li>
                <span class="font-18 font-500 gray--text">{{
                  item.city_destination
                }}</span>
              </li>
              <li>
                <span class="font-18 font-500 gray--text">{{
                  item.address_destination
                }}</span>
              </li>
            </ul>
          </v-col>
        </v-col>
        <v-col cols="12" md="7" class="mb-10">
          <google-map
            :keyUpdated="keyUpdated"
            :location="center"
            :markers="markers"
            :path="path"
          ></google-map>
        </v-col>
      </v-row>
    </div>
    <!-- edit shipment -->
    <div v-if="editShipment">
      <edit-shipment-details
        :item="item"
        :isEdit="true"
        :isEditProduct="item.city_origin ? false : true"
        :isEditProductAdmin="true"
      ></edit-shipment-details>

      <edit-shipment-address
        class="mt-5"
        :item="item"
        :isEdit="true"
        :isEditProduct="item.city_origin ? false : true"
        :isEditProductAdmin="true"
      ></edit-shipment-address>
      <div class="text-center d-flex">
        <div class="px-5 mt-5">
          <v-btn
            class="c-btn mb-10 mx-2"
            color="grey"
            height="37"
            @click="cancelHandler"
            ><span class="white--text font-300 font-20">{{
              $t("cancel")
            }}</span></v-btn
          >
          <v-btn
            class="c-btn mb-10 mx-2"
            color="primary"
            height="37"
            @click="editHandler"
            ><span class="white--text font-300 font-20">{{
              $t("save")
            }}</span></v-btn
          >
        </div>
      </div>
    </div>

    <!-- delete dialog -->
    <v-dialog v-model="deleteDialog" max-width="400" content-class="white">
      <delete-shipment
        @close="deleteDialog = false"
        @ok="okHandler"
        url="/deleteShipmentBySender"
        :id="{ key: 'shipment_id', id: item.id }"
      ></delete-shipment>
    </v-dialog>
  </div>
</template>

<script>
import DeleteShipment from "@/components/dialogs/DeleteShipment.vue";
import GoogleMap from "../../../../../components/base/GoogleMap.vue";
import EditShipmentDetails from "@/views/add-shipment/components/EnterDetails";
import EditShipmentAddress from "@/views/add-shipment/components/EnterAddress";
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    DeleteShipment,
    GoogleMap,
    EditShipmentDetails,
    EditShipmentAddress,
  },
  data: () => ({
    readMore: false,
    settings: {
      arrows: false,
      dots: true,
      focusOnSelect: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    },
    deleteDialog: false,
    markers: [
      { lat: "", lng: "" },
      { lat: "", lng: "" },
    ],
    path: [],
    keyUpdated: false,
    center: { lat: 30, lng: 30 },
    selectedDeleteID: {},

    editShipment: false,
  }),
  methods: {
    okHandler() {
      this.$router.replace("/profile/shipments");
    },
    async editHandler() {
      let payload = {
        isEdit: true,
        images: [],
        obj: this.item,
      };
      this.item.images.forEach((img) => {
        if (!img.id) {
          payload.images.push(img.file);
        }
      });
      let res = await this.$store.dispatch("submitShipment", payload);
      if (res.data.success) {
        this.$store.dispatch("showSnack", {
          text: "Shipment Added Successfully",
          color: "success",
        });
        this.editShipment = false;
        this.$emit("itemUpdated");
      } else {
        this.$store.dispatch("showSnack", {
          text: res.message,
          color: "error",
        });
      }
    },
    cancelHandler() {
      this.editShipment = false;
      this.$emit("cancelHandler");
    },
  },
  created() {
    this.markers[0].lat = +this.item.address_lat_origin;
    this.markers[0].lng = +this.item.address_long_origin;
    this.markers[1].lat = +this.item.address_lat_destination;
    this.markers[1].lng = +this.item.address_long_destination;
    this.center = this.markers[0];

    this.path[0] = {
      lat: +this.item.address_lat_origin,
      lng: +this.item.address_long_origin,
    };
    this.path[1] = {
      lat: +this.item.address_lat_destination,
      lng: +this.item.address_long_destination,
    };
    this.keyUpdated = !this.keyUpdated;
  },
};
</script>

<style lang="scss">
.shipment-details {
  &__slider {
    border: 1px solid 999999;
  }
  &__slide {
    img {
      position: relative;
      border-radius: 5px;
    }
  }
  .slick-list,
  .slick-list * {
    height: 100%;
    border-radius: 5px;
  }
}
</style>
